import {INSTANCE_CONFIGURATION_SETTING_HOME_PAGE, ROUTE_APP} from "./Constants";
//Do not import any other file except Constants in this file

export const APP_CONFIGS_API_BASE_URL = "apiBaseUrl";

export let env = "aws";
export let VERSION = "1.0.15";
export let VERSION_HASH = "670b3b0";
export const LOGIN_WITH_LINKED_IN = true;

//keep these here as referring from costants may not work
const ROUTE_ACCOUNT_LOGIN = 'app/account/login';
const BACKEND_PATH_ACCOUNT_LOGIN_LINKEDIN_CALLBACK = 'egaAccount/login/linkedIn/callback';

//A global switch to enable disable trace completely
export const ENABLE_TRACE = true;
//A global switch to enable all traces this will disable check to check if trace is enabled for
//a name and every call to Trace.trace will be logged
export const ENABLE_ALL_TRACES = false;

export const DOWNLOAD_SIZE = '111MB';


let SYSTEM_CONTEXT_VERSION = `egContext/eg-v1.jsonld`;

export const LINKEDIN_CLIENT_ID = '778ozb826vwmy8';

let appConfigs = {}
let backendConfigs = {}

export function initAppConfigs(config) {
    appConfigs = config;
}

export function getLocalBackendPort() {
    let url = new URL(window.location.origin);
    if(url.port === "3000") {
        return "8080";
    } else if (url.port === "3001") {
        return "8081";
    } else {
        return url.port;
    }
}

export function getBackendBaseEndpoint() {
    if(backendConfigs.endpoint) {
        return backendConfigs.endpoint;
    }
    let baseUrl = appConfigs[APP_CONFIGS_API_BASE_URL];
    if(baseUrl) {
        return baseUrl.endsWith("/") ? baseUrl : baseUrl + "/";
    } else {
        let defaultBackendBaseEndpoint = env === 'local'
            ? `http://localhost:${getLocalBackendPort()}/`
            : window.location.origin + '/';
        return defaultBackendBaseEndpoint;
    }
}

export function getEGSystemContextURL() {
    return getBackendBaseEndpoint() + SYSTEM_CONTEXT_VERSION;
}

export function getLinkedInRedirectURI() {
    return getBackendBaseEndpoint() + BACKEND_PATH_ACCOUNT_LOGIN_LINKEDIN_CALLBACK;
}

export function loginWithLinkedInPage() {
    let  FRONTEND_BASE_ENDPOINT = 'http://localhost:3000/';

    return env === 'local'
        ? FRONTEND_BASE_ENDPOINT + ROUTE_ACCOUNT_LOGIN
        : getBackendBaseEndpoint() + ROUTE_ACCOUNT_LOGIN;
}

export function isMangedInstance() {
    return appConfigs["instanceType"] !== 'NonManagedInstance';
}

export function isAccountInstance() {
    return appConfigs["accountEnabled"] === 'true';
}

export function isMultipleDatasetsInstance() {
    return appConfigs["multipleDatasets"] === 'true';
}

export function isTrialInstance() {
    return appConfigs["trialInstance"] === 'true';
}

export function enabledTestConfig() {
    return appConfigs["enableTestConfig"] === 'true';
}

export function isAuthenticationDisabled() {
    return appConfigs["isAuthenticationDisabled"] === 'true';
}

export function isSSOEnabled() {
    return appConfigs["ssoEnabled"] === 'true';
}

export function isAIEnabled() {
    return appConfigs["aiEnabled"] === 'true';
}

export function setDatasetLevelAuthenticationType(dataset, authenticationType) {
    appConfigs[dataset] = {authenticationType: authenticationType};
}

export function setHomePage(homePage) {
    appConfigs[INSTANCE_CONFIGURATION_SETTING_HOME_PAGE] = homePage;
}

export function getConfigForDataset(datasetLabel) {
    return appConfigs[datasetLabel];
}

export function getAppConfigurationAPIEndpoint() {
    let url = new URL(window.location.href);
    let prefixPath = url.pathname.substr(0, url.pathname.indexOf(ROUTE_APP));
    prefixPath = prefixPath ? prefixPath : "/";
    prefixPath = prefixPath.endsWith("/") ? prefixPath : prefixPath + "/";
    let staticAppConfigPath =  prefixPath + 'static/app-configuration';

    if(env === 'local') {
        return `http://localhost:${getLocalBackendPort()}${staticAppConfigPath}`;
    } else {
        let origin = window.location.origin;
        return origin + staticAppConfigPath;
    }
}

export function getSPARQLEndpointPathFromAppConfig() {
    let path = appConfigs["sparqlEndpointPath"];
    return path?.startsWith('/') ? path.replace('/', '') : path;

}

export function getBaseAIModels() {
    let list = appConfigs["aiModels"].split(",");
    return list.map(l => l.trim()).filter(l => l);
}

export function getSSOEndpoint() {
    let url = new URL(window.location.href);
    let prefixPath = url.pathname.substr(0, url.pathname.indexOf(ROUTE_APP));
    prefixPath = prefixPath ? prefixPath : "/";
    prefixPath = prefixPath.endsWith("/") ? prefixPath : prefixPath + "/";
    let staticPath =  '/sso/saml2';

    if(env === 'local') {
        return `http://localhost:${getLocalBackendPort()}${staticPath}`;
    } else {
        let origin = window.location.origin;
        return origin + staticPath;
    }
}
