import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import Grid from "@material-ui/core/Grid";
import {
    ALIAS_SH_CLASS,
    AT_TYPE,
    ID,
    STYLE_GRID_ITEM_SPACING,
    TYPE,
    TYPE_OWL_ONTOLOGY,
    TYPE_RDF_TYPE,
    TYPE_SKOS_COLLECTION, TYPE_SKOS_CONCEPT,
    TYPE_SKOS_CONCEPT_SCHEME,
    TYPE_SKOS_ORDERED_COLLECTION
} from "../../Constants";
import {
    computeClassesTree,
    createNodeForView,
    getContainerData,
    getLocalName,
    getOntologyClasses,
    getOntologyProperties,
    getPropertyName,
    getResourceId,
    isClass,
    isInValidForDataView, isObjectProperty,
    restrictMaximumCharacters,
    searchInTree,
    sort,
    toArray,
    validateMaxLength
} from "../../components/util";
import SearchFilter, {SEARCH_COMPONENT_PATH_MODE} from "../apiplayground/SearchFilter";
import FieldContainer, {fieldContainerDefaultBackgroundColor} from "../../components/FieldContainer";
import {getPropertiesSelect} from "../apiplayground/SearchMixin";
import {traceSearchBuilderUpdate} from "../../components/Trace";
import uuid4 from "uuid/v4";
import {computePropertyOptions} from "../apiplayground/SearchRequest";
import Button from "@material-ui/core/Button";
import H4Title from "../../components/H4Title";
import AllPartsLayout from "../AllPartsLayout";
import MainHeaderBarLeft from "../../components/MainHeaderBarLeft";
import TreeViewForIdSetup from "../../components/TreeViewForIdSetup";
import {
    Badge,
    Dialog,
    DialogContent,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Typography
} from "@material-ui/core";
import {getUILabel} from "./WorkspaceSettingsDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {BlockActionButton} from "../../components/ShapeToForm/ArrayType";
import {UI_LABELS_REMOVE_BLOCK} from "./UILabel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import qs from "qs";
import {getSiteHomePath} from "./Workspace";
import H3Title from "../../components/H3Title";
import H2Title from "../../components/H2Title";
import {ImageSetting, withWhiteBorder} from "./BasicSetup";
import H1Title from "../../components/H1Title";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {getAllClassesUsedInData, getAllPropertiesUsedInData} from "../../service/sparql-queries";
import {DeleteOutlined, HighlightOffOutlined, SwapVertOutlined} from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import GraphViewStyleSettings, {NODE_TYPE_STYLE} from "./GraphViewStyleSettings";
import {getNavigateToConceptLink} from "./LeftTreeComponents";
import {IRI_SKOS_IN_SCHEME} from "./TaxonomyView";

export const GRAPH_VIEW_SETTINGS = 'GRAPH_VIEW_SETTINGS';
export const PROPERTY_VALUE_VIEW_SETTINGS = 'PROPERTY_VALUE_VIEW_SETTINGS';
export const GRAPH_VIEW_PRECEDENCE = 'GRAPH_VIEW_PRECEDENCE';
export const GRAPH_VIEW_BACKGROUND_IMAGE = 'GRAPH_VIEW_BACKGROUND_IMAGE';


export function isInHiddenProperties(settings, aliasesMap, k) {
    let hiddenProperties = toArray(settings.hiddenProperties);
    let inHiddenProperties = hiddenProperties.find(p => {
        if(p === TYPE_RDF_TYPE && [TYPE, AT_TYPE].includes(k)) {
            return true;
        }
        return p === k || aliasesMap[p] === k
    });
    return inHiddenProperties;
}

function resourceHasType(resource, typeIRI, aliasesMap) {
    let types = toArray(resource[TYPE]);
    return types.includes(aliasesMap[typeIRI]) || types.includes(typeIRI);
}

export function getResourceViewOnClickURL(resource, settings, aliasesMap, location, urlParams = {}) {
    const isConcept = resourceHasType(resource, TYPE_SKOS_CONCEPT, aliasesMap);
    if(resourceHasType(resource, TYPE_SKOS_CONCEPT_SCHEME, aliasesMap) || isConcept) {
        let viewType = settings.labelProperties?.[TYPE_SKOS_CONCEPT_SCHEME]?.[VIEW_ON_CLICK]?.[VIEW_ON_CLICK_VIEW_TYPE];
        if(viewType === TAXONOMY_VIEW) {
            if(isConcept) {
                const inScheme = aliasesMap[IRI_SKOS_IN_SCHEME] || IRI_SKOS_IN_SCHEME;
                const inSchemeValueArray = toArray(resource[inScheme]);
                if(inSchemeValueArray.length > 0) {
                    const inSchemeValue = getResourceId(inSchemeValueArray[0]) || inSchemeValueArray[0];
                    return getNavigateToConceptLink(inSchemeValue, getResourceId(resource), location, undefined)
                }
            }
            let paramMap = {
                conceptSchemeId : getResourceId(resource),
                ...urlParams
            }
            let params  = qs.stringify(paramMap);
            return `${getSiteHomePath(location)}/taxonomy?${params}`;
        }
        return undefined;
    } else if(resourceHasType(resource, TYPE_OWL_ONTOLOGY, aliasesMap)) {
        let viewType = settings.labelProperties?.[TYPE_OWL_ONTOLOGY]?.[VIEW_ON_CLICK]?.[VIEW_ON_CLICK_VIEW_TYPE];
        if(viewType === ONTOLOGY_VIEW) {
            let paramMap = {
                ontologyId : getResourceId(resource),
                ...urlParams
            }
            let params  = qs.stringify(paramMap);
            return `${getSiteHomePath(location)}/ontology?${params}`;
        }
        return undefined;
    }
}

export const LABEL_PROPERTY_FOR_SEARCH = 'labelPropertySearch';

export const IMAGE_PROPERTY_FOR_SEARCH = 'imagePropertySearch';

export const DESCRIPTION_PROPERTY_FOR_SEARCH = 'descriptionPropertySearch';

export const SUMMARY_PROPERTY_FOR_SEARCH = 'summaryPropertySearch';

export const SUMMARY_PROPERTIES = 'summaryProperties';

export const DATA_VIEW_SETUP_ERRORS = 'DATA_VIEW_SETUP_ERRORS';



export const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 0,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const StyledButton = withStyles((theme) => ({
    root : {
        '&:hover': {
            backgroundColor : theme.palette.white.main
        }
    },
    label : {
        textTransform : 'none'
    }
}))(Button);

const VIEW_ON_CLICK = 'View On Click';

const VIEW_ON_CLICK_VIEW_TYPE = 'viewType';

const TAXONOMY_VIEW = 'Taxonomy View';

const ONTOLOGY_VIEW = 'Ontology View';

const COLLECTION_VIEW = 'Collection View';

const ORDERED_COLLECTION_VIEW = 'Ordered Collection View';

const TREE_VIEW = 'Tree View';

export function hideAllOtherProperties(settings, nodeId) {
    const hideAllOther = settings.labelProperties[nodeId]?.propertyOrderHideAllOther;
    return hideAllOther === true;
}

export function getPropertiesOrder(settings, nodeId) {
    const propertyOrder = settings.labelProperties[nodeId]?.propertyOrder;
    return propertyOrder ? propertyOrder : [];
}

export function initLabelPropertiesIfRequired(settings, classIRI) {
    let {labelProperties} = settings;
    if (!labelProperties) {
        settings.labelProperties = {};
    }
    if (!settings.labelProperties[classIRI]) {
        settings.labelProperties[classIRI] = {};
    }
}

export function ClassBasicSetup({cardConfig, settings, classIRI, onChange, theme, configurations, aliasesMap}) {
    let key = cardConfig.key;
    initLabelPropertiesIfRequired(settings, classIRI);

    if (!settings.labelProperties[classIRI][key]) {
        settings.labelProperties[classIRI][key] = {
            textSearch: {},
            filters: [{id: uuid4(), parentClassIRIs: [classIRI]}]
        }
    }
    let search = settings.labelProperties[classIRI][key];
    return <>

        <SearchFilter
            key={key}
            customizations={{
                mode: SEARCH_COMPONENT_PATH_MODE,
                rdfPropertiesRangeLiteralOnly: false,
                hideTextSearch: true,
                hideAddFilter: false,
                addFilterButtonTitle: 'Add Alternative',
                parentClassIRIs: [classIRI],
                title: cardConfig.title,
                configurationOptionsProvider: () => {
                    return cardConfig.ignoreBrowseLanguage === false ? <></> : <div style={{margin: '8px'}}>
                        <FormControlLabel
                            control={

                                <Switch
                                    datatest={'ignoreBrowseLanguage'}
                                    size={"small"}
                                    checked={search.ignoreBrowseLanguage || false}
                                    value={true}
                                    onChange={() => {
                                        search.ignoreBrowseLanguage = !search.ignoreBrowseLanguage;
                                        onChange();
                                    }}
                                    name="ignoreBrowseLanguage"
                                ></Switch>
                            }
                            label={<Typography style={{color: theme.palette.primary.main}}>Ignore Browse Language and
                                Show All Value(s).</Typography>}
                        />
                    </div>;

                }
            }}
            configurations={configurations}
            aliasesMap={aliasesMap}
            search={search}
            onChange={(search) => {
                traceSearchBuilderUpdate(() => {
                    console.log(search)
                }, 'DataViewSetup');
                onChange();

            }}
        />
    </>;
}

export const IMAGE_CONFIG = {title: 'Image', key: IMAGE_PROPERTY_FOR_SEARCH, ignoreBrowseLanguage : false};
export const TITLE_CONFIG = {title: 'Title', key: LABEL_PROPERTY_FOR_SEARCH};
export const DESCRIPTION_CONFIG = {title: 'Description', key: DESCRIPTION_PROPERTY_FOR_SEARCH};
export const cardConfigs = [
    TITLE_CONFIG,
    IMAGE_CONFIG,
    DESCRIPTION_CONFIG
]


export function ClassLevelImageSettings({workspace, settings, classIRI}) {
    initLabelPropertiesIfRequired(settings, classIRI);

    return <ImageSetting
        datatest={'imageSettings'}
        label={'Image'}
        workspace={workspace}
        valueObject={settings.labelProperties?.[classIRI]}
        objectKey={GRAPH_VIEW_SETTINGS}
        valueKey={GRAPH_VIEW_BACKGROUND_IMAGE}
        helpMessage={'Enter url above or upload a small PNG (.png)/ SVG (.svg) image without any background color.'}
        innerTextLabel={undefined}
        containerStyle={{padding: '0px'}}
    />;
}

function getInitialisedContainer(settings, focusNodeID) {
    initLabelPropertiesIfRequired(settings, focusNodeID);
    let settingsContainerObject = settings.labelProperties?.[focusNodeID]?.[GRAPH_VIEW_SETTINGS];
    if (!settingsContainerObject) {
        settings.labelProperties[focusNodeID][GRAPH_VIEW_SETTINGS] = {};
        settingsContainerObject = settings.labelProperties[focusNodeID][GRAPH_VIEW_SETTINGS];
    }
    if (!settingsContainerObject[NODE_TYPE_STYLE]) {
        settingsContainerObject[NODE_TYPE_STYLE] = {};
    }
    if (!settingsContainerObject[NODE_TYPE_STYLE][focusNodeID]) {
        settingsContainerObject[NODE_TYPE_STYLE][focusNodeID] = {}
    }
    return settingsContainerObject;
}

export function ClassImagePrecedence({settings, classIRI, onChange}) {
    let helperText = 'By setting higher value for this field you can adjust which image is used if a resource has multiple types.';

    const settingsContainerObject = getInitialisedContainer(settings, classIRI);

    return <div>
        <H4Title title={'Precedence'}/>
        <TextField
            value={settingsContainerObject[NODE_TYPE_STYLE][classIRI][GRAPH_VIEW_PRECEDENCE]}
            defaultValue={''}
            onChange={(event) => {
                const maxLength = 500;
                let newEvent = restrictMaximumCharacters(event, maxLength);
                const {target: {value}} = newEvent;
                settingsContainerObject[NODE_TYPE_STYLE][classIRI][GRAPH_VIEW_PRECEDENCE] = value;
                let error = validateMaxLength(value, maxLength);
                onChange();
            }}
        />
        <FormHelperText style={{padding: '0px 0px'}}>{helperText}</FormHelperText>
    </div>;
}


export function OtherPropertiesSettings({theme, aliasesMap, configurations, classes, onBlockRemove, onSwitchChange, onChange, settings, classIRI, onPropertySelect, onAddProperty}) {
    let summaryProperties = toArray(settings.labelProperties[classIRI][SUMMARY_PROPERTIES]);

    let propertyOptions = computePropertyOptions(
        {
            [ALIAS_SH_CLASS]: classIRI
        },
        aliasesMap,
        configurations,
        true,
        true,
        {
            rdfPropertiesRangeLiteralOnly: false
        }
    );

    return <div datatest={'otherProperties'} style={{
        marginTop: '8px',
        padding: '8px',
        borderRadius: '4px',
        backgroundColor: theme.palette.white.main
    }}>
        <H4Title>Other Properties</H4Title>
        {
            summaryProperties.map((sp, index) => {
                return <div datatest={'otherPropertyBlock'} key={sp.id}
                            style={{marginBottom: '8px'}}>{
                    (<FieldContainer
                        style={{padding: '8px', backgroundColor: 'rgb(238,238,238)'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{flexGrow: '1'}}/>
                            <div style={{paddingTop: '4px'}}>
                                <BlockActionButton
                                    buttonProps={{datatest: 'removeBlockButton'}}
                                    theme={theme}
                                    title={UI_LABELS_REMOVE_BLOCK}
                                    onClick={() => {
                                        summaryProperties.splice(index, 1);
                                        onBlockRemove();
                                    }}
                                    endIcon={<RemoveCircleIcon
                                        style={{color: theme.palette.grey.level3}}/>}
                                />
                            </div>
                        </div>

                        {getPropertiesSelect(propertyOptions, sp.selectedProperty, (property) => {
                            sp.selectedProperty = property;
                            onPropertySelect()
                        }, 'Property', classes, {width: '100%'}, undefined, 'largeWidthPropertyLabelRoot')}
                        <div style={{margin: '8px'}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        datatest={'isCount'}
                                        size={"small"}
                                        checked={sp.isCount || false}
                                        value={true}
                                        onChange={() => {
                                            sp.isCount = !sp.isCount;
                                            onSwitchChange();
                                        }}
                                        name="isCount"
                                    ></Switch>
                                }
                                label={<Typography
                                    style={{color: theme.palette.primary.main}}>Show
                                    Count</Typography>}
                            />
                        </div>
                        <div style={{margin: '8px'}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        datatest={'ignoreBrowseLanguage'}
                                        size={"small"}
                                        checked={sp.ignoreBrowseLanguage || false}
                                        value={true}
                                        onChange={() => {
                                            sp.ignoreBrowseLanguage = !sp.ignoreBrowseLanguage;
                                            onSwitchChange();
                                        }}
                                        name="ignoreBrowseLanguage"
                                    ></Switch>
                                }
                                label={<Typography
                                    style={{color: theme.palette.primary.main}}>Ignore
                                    Browse Language and Show All Value(s).</Typography>}
                            />
                        </div>

                        {getUILabel('Text Suffix', theme, settings, onChange, sp, ['suffix'], undefined, {padding: '0px'})}
                    </FieldContainer>)
                }</div>
            })
        }
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: '1'}}/>
            <div style={{paddingTop: '4px'}}>
                <Button
                    color={'secondary'}
                    variant={'contained'}
                    onClick={() => {
                        if (!settings.labelProperties[classIRI][SUMMARY_PROPERTIES]) {
                            settings.labelProperties[classIRI][SUMMARY_PROPERTIES] = []
                        }
                        settings.labelProperties[classIRI][SUMMARY_PROPERTIES].push(
                            {
                                id: uuid4(),
                                "title": {},
                                "selectedProperty": {}
                            }
                        )
                        onAddProperty();
                    }}
                >Add Property</Button>
            </div>
        </div>
    </div>;
}

export function SummarySettings({theme, settings, classIRI, onChange, configurations, aliasesMap, onSwitchChange, onFilterChange}) {

    if (!settings.labelProperties[classIRI][SUMMARY_PROPERTY_FOR_SEARCH]) {
        settings.labelProperties[classIRI][SUMMARY_PROPERTY_FOR_SEARCH] = {
            textSearch: {},
            filters: [{id: uuid4(), parentClassIRIs: [classIRI]}]
        }
    }

    let summarySearch = settings.labelProperties[classIRI][SUMMARY_PROPERTY_FOR_SEARCH];

    return <FieldContainer style={{padding: '8px'}}>
        {getUILabel('Title', theme, settings, onChange, summarySearch, ['title'], undefined, {padding: '0px'})}
        <div style={{margin: '8px'}}>
            <FormControlLabel
                control={
                    <Switch
                        datatest={'isCount'}
                        size={"small"}
                        checked={summarySearch.isCount || false}
                        value={true}
                        onChange={() => {
                            summarySearch.isCount = !summarySearch.isCount;
                            onSwitchChange();
                        }}
                        name="isCount"
                    ></Switch>
                }
                label={<Typography style={{color: theme.palette.primary.main}}>Show
                    Count With Title</Typography>}
            />
        </div>
        <div style={{margin: '8px'}}>
            <FormControlLabel
                control={
                    <Switch
                        datatest={'ignoreBrowseLanguage'}
                        size={"small"}
                        checked={summarySearch.ignoreBrowseLanguage || false}
                        value={true}
                        onChange={() => {
                            summarySearch.ignoreBrowseLanguage = !summarySearch.ignoreBrowseLanguage;
                            onSwitchChange();
                        }}
                        name="ignoreBrowseLanguage"
                    ></Switch>
                }
                label={<Typography style={{color: theme.palette.primary.main}}>Ignore
                    Browse Language and Show All Value(s).</Typography>}
            />
        </div>
       <SearchFilter
            customizations={{
                mode: SEARCH_COMPONENT_PATH_MODE,
                rdfPropertiesRangeLiteralOnly: false,
                hideTextSearch: true,
                hideAddFilter: true,
                hideRemoveBlockButton: true,
                noBlockBackgroundColor: true,
                title: ' '
            }}
            configurations={configurations}
            aliasesMap={aliasesMap}
            search={summarySearch}
            onChange={(search) => {
                traceSearchBuilderUpdate(() => {
                    console.log(search)
                }, 'DataViewSetup');
                onFilterChange()
            }}f
        />
    </FieldContainer>;
}

export function PropertyOrderSettings({settings, classIRI, theme, propertyTreeData, classes, onSwitchChange, onChange}) {
    const [showAddPropertyIndex, setShowAddPropertyIndex] = useState();

    const propertyOrderArray = toArray(settings.labelProperties[classIRI].propertyOrder);
    const disableHideAllOther = toArray(propertyOrderArray).length === 0;
    const hideAllOther = hideAllOtherProperties(settings, classIRI) && disableHideAllOther === false;
    return <><div>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disableHideAllOther}
                            datatest={'switchHideAllOtherSwitch'}
                            checked={hideAllOther}
                            value={true}
                            size={'small'}
                            onChange={(e) => {
                                const {target: {checked}} = e;
                                settings.labelProperties[classIRI].propertyOrderHideAllOther = checked === true ? true : false;
                                onChange();
                                onSwitchChange();
                            }}
                            name="switchHideAllOther"
                        />
                    }
                    label={<Typography style={{color: theme.palette.primary.main}}>Hide all other
                        properties</Typography>}
                />
            </div>
            {propertyTreeData && propertyOrderArray.map((p, index) => {
                let po = propertyTreeData.find(po => po.id === p);
                //Below is needed in case site is imported to some other setup where there are no ontologies loaded.
                //This allows user to remove the properties from config.
                if (!po) {
                    po = {
                        [ID]: p
                    };
                }
                return <div key={po.id + index}>
                    <FieldContainer
                        style={{backgroundColor: theme.palette.white.main, margin: '8px 0px', display: 'flex'}}>
                        <div>
                            {po.title ? <><H3Title title={po.title}/></> : <></>}
                            {po.id}
                        </div>
                        <div style={{flexGrow: '1'}}></div>
                        <IconButton datatest={'deletePropertyOrderButton'} size={'small'} onClick={() => {
                            settings.labelProperties[classIRI].propertyOrder = settings.labelProperties[classIRI].propertyOrder.filter(p => p !== po.id);
                            onChange();
                        }}><DeleteOutlined></DeleteOutlined></IconButton>
                    </FieldContainer>
                    {
                        propertyOrderArray.length - 1 === index
                            ? <></>
                            : <IconButton
                                datatest={'swapPropertyOrderButton'}
                                size={'small'}
                                onClick={() => {
                                    let arr = propertyOrderArray;
                                    let a = arr[index];
                                    let b = arr[index + 1];
                                    arr[index + 1] = a;
                                    arr[index] = b;
                                    onChange();
                                }}
                            ><SwapVertOutlined></SwapVertOutlined></IconButton>
                    }
                </div>;
            })}
            <div style={{textAlign: 'center'}}>
                <IconButton datatest={'addPropertyOrderButton'} size={'small'} onClick={() => {
                    setShowAddPropertyIndex(0);
                }}>
                    <AddCircleIcon></AddCircleIcon>
                </IconButton>
            </div>
            {
                showAddPropertyIndex !== undefined &&
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={true}
                    datatest={'addPropertyOrderDialog'}
                    classes={{paper: classes.dialogPaper}}
                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <H2Title title={'Add/remove order properties'}/>
                    </DialogTitle>
                    <DialogContent datatest={'dialogContent'} style={{paddingBottom: '0px'}}>
                        {toArray(propertyTreeData).map(p => {
                            let isOptionChecked = propertyOrderArray.includes(p.id);
                            return <FieldContainer datatest={'property-' + p.id} key={p.id}
                                                   style={{marginTop: '8px', display: 'flex'}}>
                                <div>
                                    {p.title ? <><H3Title style={{marginBottom: '8px'}}
                                                          title={p.title}/></> : <></>}
                                    {p.id}
                                </div>
                                <div style={{flexGrow: '1'}}></div>
                                <Checkbox
                                    datatest={'checkbox-' + p.id}
                                    edge="start"
                                    checked={isOptionChecked}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={() => {
                                        if (settings.labelProperties?.[classIRI].propertyOrder === undefined) {
                                            settings.labelProperties[classIRI].propertyOrder = [];
                                        }
                                        if (isOptionChecked) {
                                            settings.labelProperties[classIRI].propertyOrder = settings.labelProperties[classIRI].propertyOrder.filter(po => po !== p.id);
                                        } else {
                                            settings.labelProperties[classIRI].propertyOrder.push(p.id);
                                        }
                                        onChange();
                                    }}
                                />
                            </FieldContainer>;
                        })}
                    </DialogContent>
                    <DialogActions>
                        <div style={{flexGrow: '1'}}></div>
                        <Button
                            datatest={'closeDialogButton'}
                            variant={"contained"}
                            color="secondary"
                            onClick={() => {
                                setShowAddPropertyIndex(undefined);
                            }}
                        >Close</Button>
                    </DialogActions>
                </Dialog>
            }</>;
}

export async function getPropertyTreeData(configurations, settings, aliasesMap, ontology) {
    let r = await getAllPropertiesUsedInData();

    const titleProvider = (o) => {
        let bl = settings?.browseLanguages?.find(bl => bl.isDefault === true);
        let edgeLabel = getPropertyName({}, o.id, ontology, bl, false);

        const startLabel = aliasesMap[o.id] || getLocalName(o.id, false);
        return  startLabel + (edgeLabel && startLabel !== edgeLabel ? ` (${edgeLabel})` : '');
    };

    const propertyTreeData = getOntologyProperties(configurations).map(o => {
        return createNodeForView(o, titleProvider )
    })
    const rest = r.filter(r => propertyTreeData.find((pt) => pt.id === r) === undefined).map(r => ({ id : r})).map(o => {
        return createNodeForView(o, titleProvider )
    })
    const sortedPropertyTreeData = sort([...propertyTreeData, ...rest], 'title');

    return sortedPropertyTreeData;
}

export async function getAllClassesData(configurations, settings, aliasesMap) {
    let r = await getAllClassesUsedInData();

    const titleProvider = (o) => {
        const startLabel = aliasesMap[o.id] || getLocalName(o.id, false);
        return  startLabel;
    };

    const propertyTreeData = getOntologyClasses(configurations).map(o => {
        return createNodeForView(o, titleProvider )
    })
    const rest = r.filter(r => propertyTreeData.find((pt) => pt.id === r) === undefined).map(r => ({ id : r})).map(o => {
        return createNodeForView(o, titleProvider )
    })
    const sortedPropertyTreeData = sort([...propertyTreeData, ...rest], 'title');

    return sortedPropertyTreeData;
}


export function handlePropertyChange(node, settings) {
    let {checked, value} = node.target;
    if (settings.hiddenProperties === undefined) {
        settings.hiddenProperties = []
    }
    if (checked) {
        if (!settings.hiddenProperties.includes(value)) {
            settings.hiddenProperties.push(value);
        }
    } else {
        settings.hiddenProperties = settings.hiddenProperties.filter(p => p !== value);
    }
}

export function HiddenPropertySettings({settings, aliasesMap, onChange, disableTitle}) {
    let hiddenProperties = toArray(settings.hiddenProperties).map(r => ({id: r})).map(o => {
        return createNodeForView(o, () => {
            return aliasesMap[o.id] || getLocalName(o.id, false);
        })
    });
    let hiddenPropertiesSorted = sort(hiddenProperties, 'title');
    return <Grid container spacing={STYLE_GRID_ITEM_SPACING} xs={12}>
        {
            disableTitle ||
            <Grid item xs={12}>
                <H1Title title={'Hidden properties'}/>
            </Grid>
        }
        {hiddenPropertiesSorted.map((p, index) => {
            return <Grid datatest={'hiddenProperty'} key={p.id + '-' + index} item xs={12}> {
               withWhiteBorder(<FieldContainer style={{padding: '16px'}}>
                    <div datatest={'hiddenProperty-'+p.title+'-'+index} style={{display: 'flex'}}>
                        <div>
                            <div><H4Title title={p.title}></H4Title></div>
                            <div style={{marginTop: '8px'}}>{p.id}</div>
                        </div>

                        <div style={{flexGrow: 1}}></div>
                        <div>
                            <IconButton
                                datatest={'deleteButton'}
                                onClick={(event) => {
                                    handlePropertyChange({
                                        target: {
                                            checked: false,
                                            value: p.id
                                        }
                                    }, settings);
                                    onChange(uuid4());
                                }}
                                style={{marginRight: '8px'}}
                                size={'small'}>
                                <HighlightOffOutlined></HighlightOffOutlined>
                            </IconButton>
                        </div>
                    </div>

                </FieldContainer>)
            }
            </Grid>
        })}
    </Grid>
}

export const clickableURL = 'Clickable URL';
export const imageTagSource = 'Image Tag Source';
export const RENDER_html = 'HTML';
export const mathML = 'MathML';
export const svg = 'SVG';
export const mermaidDiagram = 'Mermaid Diagram';
export const markdown = 'Markdown';
const renderValueAsOptions = toArray(['Default', RENDER_html, mathML, imageTagSource, svg, clickableURL, mermaidDiagram, markdown].sort());

export const RENDER_VALUE_AS = 'RENDER_VALUE_AS';

class DataViewSetup extends Component {
    constructor(props) {
        super(props);
        const treeData = getOntologyClasses(props.configurations).map(o => {
            return createNodeForView(o, () => {return  props.aliasesMap[o.id];} )
        })

        this.state = {
            treeData: sort(treeData, 'title'),
        //    propertyTreeData :[]
        }
    }

    componentDidMount() {
        this.setupChanged();
        let {aliasesMap, ontology, settings, configurations} = this.props;
        getPropertyTreeData(configurations, settings, aliasesMap, ontology).then(data => {
            this.setState({propertyTreeData : data})
        })
    }



    setupChanged = () => {
        let {onChange, settings} = this.props;
        this.validate();
        onChange(settings[DATA_VIEW_SETUP_ERRORS]);
    }

    validate = () => {
        let {settings, ontology} = this.props;
        delete settings[DATA_VIEW_SETUP_ERRORS];
        let find = ontology.filter(o => isClass(o)).find(cl => this.getSetupValidCount(cl[ID]) >= 1);
        if(!find) {
            settings[DATA_VIEW_SETUP_ERRORS] = {
                'all' : 'Please configure view settings.'
            }
        }
    }

    onNodeClick = (node) => {
        let {location} = this.props;
        let focusNode = searchInTree(node.id, this.state.treeData)
        if (focusNode) {
            this.setState({focusNode: focusNode})
        }
    }

    renderOntologyClasses = () => {
        const {treeData, focusNode} = this.state;
        const {settings, theme, location, ontology, aliasesMap, configurations} = this.props;

        let sortedTree = treeData.map(d => ({
            id : d.id,
            tooltip: d.id,
            title : aliasesMap[d.id],
        }));

        const classShape = computeClassesTree(ontology, aliasesMap);
        let containers =  getContainerData(configurations)
        return <>
            <TreeViewForIdSetup
                hasTabs={true}
                idGeneratingClasses={containers}
                focusedNodeId={focusNode ? focusNode.id : undefined}
                bootstrap={'true'}
                key={'classTree'}
                data={sortedTree}
                location={location}
                treeData={classShape}
                viewType={'tree'}
                toggle={true}
                toggleDisabled={true}
                onNodeClick={this.onNodeClick}
                renderLabelPrefix={ (node) => {
                    let classIRI = node.id;
                    let count = this.getSetupValidCount(classIRI);
                    let color = count <= 4 ? 'error' : 'primary';
                    return <StyledBadge style={{backgroundColor: color}} badgeContent={count+'/5'} color={color}/>;
                }}
            />
        </>;

    }


    getSetupValidCount = (classIRI) => {
        let {settings} = this.props;
        let count = 5;
        [LABEL_PROPERTY_FOR_SEARCH, DESCRIPTION_PROPERTY_FOR_SEARCH, SUMMARY_PROPERTY_FOR_SEARCH].forEach(l => {

            let labelPropertyValue = settings?.labelProperties?.[classIRI]?.[l];
            let isInvalid = labelPropertyValue
                ? isInValidForDataView(labelPropertyValue.filters)
                : true;
            if (isInvalid) {
                count--;
            }
        });

        [IMAGE_PROPERTY_FOR_SEARCH].forEach(l => {
            let labelPropertyValue = settings?.labelProperties?.[classIRI]?.[l];
            let found = labelPropertyValue?.filters?.find(f => !f.property);
            if (found) {
                count--;
            }
        });

        let summaryProperties = settings?.labelProperties?.[classIRI]?.[SUMMARY_PROPERTIES];
        if (summaryProperties === undefined || summaryProperties?.length === 0) {
            count--;
        } else {
            let find = summaryProperties.find(sp => sp.selectedProperty?.value === undefined);
            if (find) {
                count--;
            }
        }
        return count;
    }

    getStepLeftContent = () => {
        return <>
            {this.getViewButtonGroup()}
            {this.isClassView()
                ? this.renderOntologyClasses()
                : this.renderOntologyProperties()}
        </>;
    }


    renderOntologyProperties = () => {
        const {location, aliasesMap, settings} = this.props;
        const {propertyTreeData, focusNode, focusedPropertyNodeId} = this.state;


        let sortedTree = toArray(propertyTreeData).map(d => ({
            id : d.id,
            tooltip: d.id,
            title : d.title,
            subTitle: d.id
        }));

        const filterOptions = createFilterOptions({
            matchFrom: "any",
            stringify: (option) => {
                return option.title + option.id;
            }
        });

        return <React.Fragment>
            <TreeViewForIdSetup
                containerHeightAdjust={-24}
                focusedNodeId={focusNode ? focusNode.id : undefined}
                onChange={this.handlePropertyChange}
                hasTabs={false}
                key={'propertyTree'}
                data={sortedTree}
                location={location}
                treeData={propertyTreeData}
                viewType={'list'}
                toggle={true}
                disableViewType={true}
                toggleSwitchStateProvider={(node) => {
                    return settings.hiddenProperties?.includes(node.id);
                }}
                disabledNodeClickOnSearch={false}
                onNodeClick={(node) => {
                    let focusNode = searchInTree(node.id, this.state.propertyTreeData)
                    if (focusNode) {
                        this.setState({focusNode: focusNode})
                    }
                }}
                searchRenderOption={(option) => {
                    return (
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Typography variant="h3" color="textSecondary">
                                    {option.title || getResourceId(option)}
                                </Typography>
                                <div>{option.id}</div>
                            </Grid>
                        </Grid>
                    );
                }}
                searchFilterOptions={filterOptions}
            />
        </React.Fragment>;

    }

    handlePropertyChange = (node) => {
        const {settings} = this.props;
        handlePropertyChange(node, settings);
        this.props.onChange?.();
        this.setState({});
    }

    getViewButtonGroup = () => {
        let {theme} = this.props;
        return <div style={{paddingRight : '16px' , paddingTop: '8px', paddingBottom : '4px'}}>
            <ButtonGroup fullWidth={true} style={{color: theme.palette.primary.main, borderColor : theme.palette.grey.level2}} size={'small'}>
                <StyledButton
                    datatest={'CLASS_VIEW'}
                    disableRipple={true}
                    disabled={this.isClassView()}
                    color={this.isClassView() ? 'secondary' : 'primary'}
                    style={{borderColor : this.isClassView() && theme.palette.secondary.main,  ...(this.isClassView() ? {borderRightColor : theme.palette.secondary.main} : {})}}
                    onClick={(ev) => this.setState({treeTabValue : 'CLASS_VIEW', focusNode : undefined})}
                >Class</StyledButton>
                <StyledButton
                    datatest={'PROPERTY_VIEW'}
                    disableRipple={false}
                    disabled={this.isPropertyView()}
                    color={this.isPropertyView() ? 'secondary' : 'primary'}
                    style={{borderColor : this.isPropertyView() ? theme.palette.secondary.main : theme.palette.grey.level2,  ...(this.isClassView() ? {borderLeftColor : theme.palette.secondary.main} : {})}}
                    onClick={(ev) => this.setState({treeTabValue : 'PROPERTY_VIEW', focusNode : undefined})}
                >Property</StyledButton>
            </ButtonGroup>
        </div>;
    }

    isPropertyView = () => {
        let {treeTabValue} = this.state;
        let isPropertyView = treeTabValue === 'PROPERTY_VIEW';
        return isPropertyView;
    }

    isClassView = () => {
        let {treeTabValue} = this.state;
        let isClassView = treeTabValue === undefined || treeTabValue === 'CLASS_VIEW' ;
        return isClassView;
    }

    initLabelPropertiesIfRequired = (classIRI) => {
        let {settings} = this.props;
        initLabelPropertiesIfRequired(settings, classIRI);
    }

    showTreeViewSetup = () => {
        let {configurations, ontology, settings, shapes, aliasesMap, classes, onChange, theme} = this.props;
        let {focusNode} = this.state;

        if(settings.labelProperties?.[focusNode.id]?.[VIEW_ON_CLICK]?.viewType === TREE_VIEW) {

            let propertyOptions = computePropertyOptions(
                {
                    [ALIAS_SH_CLASS]: focusNode.id
                },
                aliasesMap,
                configurations,
                true,
                true
            );
            let propertyOptions2 = computePropertyOptions(
                {},
                aliasesMap,
                configurations,
                true,
                true
            );
            return <Grid container spacing={1}>
                <Grid item xs={6}>{
                    getPropertiesSelect(propertyOptions, settings.labelProperties?.[focusNode.id]?.[VIEW_ON_CLICK]?.topProperty, (property) => {
                    settings.labelProperties[focusNode.id][VIEW_ON_CLICK].topProperty = property;
                    onChange();
                    this.setState({});
                }, 'Top Nodes Property', classes, {width: '100%'}, undefined, 'largeWidthPropertyLabelRoot')}</Grid>
                <Grid item xs={6}>{
                    getPropertiesSelect(propertyOptions2, settings.labelProperties?.[focusNode.id]?.[VIEW_ON_CLICK]?.nodeExpandProperty, (property) => {
                        settings.labelProperties[focusNode.id][VIEW_ON_CLICK].nodeExpandProperty = property;
                        onChange();
                        this.setState({});
                    }, 'Node Expand Property', classes, {width: '100%'}, undefined, 'largeWidthPropertyLabelRoot')}</Grid>
            </Grid>;
        }


    }

    getViewOption = () => {
        let {focusNode} = this.state;

        if(focusNode.id === TYPE_OWL_ONTOLOGY) {
            return ONTOLOGY_VIEW;
        } else if (focusNode.id === TYPE_SKOS_CONCEPT_SCHEME) {
            return TAXONOMY_VIEW;
        } else if (focusNode.id === TYPE_SKOS_COLLECTION) {
            return COLLECTION_VIEW;
        } else if (focusNode.id === TYPE_SKOS_ORDERED_COLLECTION) {
            return ORDERED_COLLECTION_VIEW;
        } else {
            return undefined;
        }
    }


    middleContent = () => {
        let {configurations, ontology, settings, shapes, workspace, aliasesMap, classes, onChange, theme} = this.props;
        let {focusNode, propertyTreeData} = this.state;

        const focusNodeID = focusNode?.id;

        if(this.isPropertyView()) {
            if(focusNode) {
                const isOP = isObjectProperty(focusNode.backingObject);
                this.initLabelPropertiesIfRequired(focusNodeID);
                let settingsContainerObject = settings.labelProperties?.[focusNodeID]?.[GRAPH_VIEW_SETTINGS];
                if(!settingsContainerObject) {
                    settings.labelProperties[focusNodeID][GRAPH_VIEW_SETTINGS] = {};
                    settingsContainerObject = settings.labelProperties[focusNodeID][GRAPH_VIEW_SETTINGS];
                }
                let propertyViewSettingsContainerObject = settings.labelProperties?.[focusNodeID]?.[PROPERTY_VALUE_VIEW_SETTINGS];
                if(!propertyViewSettingsContainerObject) {
                    settings.labelProperties[focusNodeID][PROPERTY_VALUE_VIEW_SETTINGS] = {};
                    propertyViewSettingsContainerObject = settings.labelProperties[focusNodeID][PROPERTY_VALUE_VIEW_SETTINGS];
                }

                return <Grid key={focusNodeID} container spacing={STYLE_GRID_ITEM_SPACING} xs={12}>
                    <Grid item xs={12}>
                        <H1Title title={aliasesMap[focusNodeID]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <H2Title style={{margin: '8px 0px'}}>Property Level Graph/Image Settings</H2Title>
                        <FieldContainer>
                                <GraphViewStyleSettings
                                    workspace={workspace}
                                    graphStyle={settingsContainerObject}
                                    ontology={ontology}
                                    configurations={configurations}
                                    aliasesToIRIMap={this.props.aliasesToIRIMap}
                                    aliasesMap={aliasesMap}
                                    settings={settings}
                                    browseLanguage={settings?.browseLanguages?.find(bl => bl.isDefault === true)}
                                    onClose={undefined}
                                    openStyleFor={{elementId : focusNodeID}}
                                    onStyleSettingsChange={() => {
                                    }}
                                    styleContainerWidth={{}}
                                    containerStyle={{
                                        backgroundColor: fieldContainerDefaultBackgroundColor
                                    }}
                                    hideProperty={['background-image']}
                                    elementObject={{
                                        embeddedForType: true,
                                        data : function (e) {
                                            return {
                                                propertyIRI : focusNodeID
                                            }
                                        },
                                        isNode : function (e) {
                                            return false;
                                        }
                                    }}
                                />

                        </FieldContainer>
                    </Grid>
                    {
                        isOP ? <></> :
                        <Grid item xs={12}>
                            <H2Title style={{margin: '8px 0px'}}>Property View Settings</H2Title>
                            <FieldContainer>
                                <FormControl size={'small'} fullWidth={true} variant="outlined"
                                             className={classes.formControl}>
                                    <InputLabel id="render-value-as-label">Render Value As</InputLabel>
                                    <Select
                                        datatest={'renderValueSelect'}
                                        labelId="render-value-as-label"
                                        id="render-value-as-label-select"
                                        value={propertyViewSettingsContainerObject?.[RENDER_VALUE_AS] || 'Default'}
                                        onChange={(ev) => {
                                            let resultView = ev.target.value;
                                            this.initLabelPropertiesIfRequired(focusNodeID);
                                            settings.labelProperties[focusNodeID][PROPERTY_VALUE_VIEW_SETTINGS] = {
                                                [RENDER_VALUE_AS]: resultView
                                            };
                                            onChange();
                                            this.setState({})
                                        }}
                                        label="Render Value As"
                                    >
                                        {settings && renderValueAsOptions.map(l => <MenuItem key={l}
                                                                                             value={l}>{l}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </FieldContainer>

                        </Grid>
                    }
                </Grid>;
            } else {
                return <HiddenPropertySettings
                    settings={settings}
                    aliasesMap={aliasesMap}
                    onChange={(refreshUUID) => {
                        this.props.onChange?.();
                        if(refreshUUID) {
                            this.setState({refreshUUID});
                        } else {
                            this.setState({});
                        }
                    }}
                />;
            }
        }
        if(!focusNode) {
            return <></>;
        }

        let viewOption = this.getViewOption();
        let viewOptions = viewOption
            ? ['Resource Details',  viewOption] : ['Resource Details'];

        return <Grid key={focusNodeID} container spacing={STYLE_GRID_ITEM_SPACING} xs={12}>
            <Grid item xs={12}>
                <H1Title title={aliasesMap[focusNodeID]}/>
            </Grid>
            <Grid item xs={12}>
                <FieldContainer style={{marginBottom: '8px', padding: '16px'}}>

                    <FormControl size={'small'} fullWidth={true} variant="outlined" className={classes.formControl}>
                        <InputLabel id="view-on-card-label">View On Card Click</InputLabel>
                        <Select
                            disabled={viewOptions.length < 2}
                            labelId="view-on-card-label"
                            id="view-on-card-label-select"
                            value={settings.labelProperties?.[focusNodeID]?.[VIEW_ON_CLICK]?.[VIEW_ON_CLICK_VIEW_TYPE] || 'Resource Details'}
                            onChange={(ev) => {
                                let resultView = ev.target.value;
                                this.initLabelPropertiesIfRequired(focusNodeID);
                                settings.labelProperties[focusNodeID][VIEW_ON_CLICK] = {
                                    [VIEW_ON_CLICK_VIEW_TYPE]: resultView
                                };
                                onChange();
                                this.setState({})
                            }}
                            label="View On Card Click"
                        >
                            {settings && toArray(viewOptions).map(l => <MenuItem key={l}
                                                                                 value={l}>{l}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {this.showTreeViewSetup()}

                </FieldContainer>
            </Grid>
            <Grid item xs={12}>
                <H2Title style={{margin: '16px 0px'}}>Basic View</H2Title>
                {
                    ontology.filter(o => isClass(o) && o[ID] === focusNodeID).map(cl => {
                        let classIRI = cl[ID];
                        this.initLabelPropertiesIfRequired(classIRI);

                        if (!settings.labelProperties[classIRI][SUMMARY_PROPERTY_FOR_SEARCH]) {
                            settings.labelProperties[classIRI][SUMMARY_PROPERTY_FOR_SEARCH] = {
                                textSearch: {},
                                filters: [{id: uuid4(), parentClassIRIs: [classIRI]}]
                            }
                        }

                        return <FieldContainer key={classIRI} style={{marginBottom: '8px', padding: '8px'}}>
                            <div>
                                {
                                    cardConfigs.map(cc => {
                                        return <ClassBasicSetup
                                            cardConfig={cc}
                                            settings={settings}
                                            classIRI={classIRI}
                                            onChange={() => {
                                                this.setupChanged();
                                                this.setState({});
                                                onChange();
                                            }}
                                            theme={theme}
                                            configurations={configurations}
                                            aliasesMap={aliasesMap}
                                        />;

                                    })
                                }
                                <OtherPropertiesSettings
                                    theme={theme}
                                    aliasesMap={aliasesMap}
                                    configurations={configurations}
                                    classes={classes}
                                    onChange={() => {
                                        onChange();
                                    }}
                                    settings={settings}
                                    classIRI={classIRI}
                                    onPropertySelect={() => {
                                        this.setupChanged();
                                        this.setState({});
                                    }}
                                    onBlockRemove={() => {
                                        this.setState({});
                                    }}
                                    onSwitchChange={() => {
                                        onChange();
                                        this.setState({});
                                    }}
                                    onAddProperty={() => {
                                        this.setState({});
                                        this.setupChanged();
                                    }}
                                />
                                <div
                                    datatest={'summarySection'}
                                    style={{
                                        marginTop: '8px',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        backgroundColor: theme.palette.white.main
                                    }}
                                >
                                    <H4Title>Summary Section</H4Title>
                                    <SummarySettings
                                        classIRI={classIRI}
                                        theme={theme}
                                        settings={settings}
                                        onChange={onChange}
                                        configurations={configurations}
                                        aliasesMap={aliasesMap}
                                        onSwitchChange={() => {
                                            onChange();
                                            this.setState({})
                                        }}
                                        onFilterChange={() => {
                                            this.setState({});
                                            this.setupChanged();
                                        }}
                                    />
                                </div>
                            </div>
                        </FieldContainer>
                    })
                }
            </Grid>
            <Grid item xs={12}>
                <H2Title style={{margin: '16px 0px'}}>Class Level Graph/Image Settings</H2Title>
                <FieldContainer style={{padding: '0px'}}>
                    <ClassLevelImageSettings
                        workspace={workspace}
                        settings={settings}
                        classIRI={focusNodeID}
                    />
                    {this.renderGraphViewSettings()}
                </FieldContainer>

            </Grid>
            {this.renderPropertyViewSetup(settings, focusNode, propertyTreeData, classes)}

        </Grid>;
    }

    renderGraphViewSettings = () => {
        let {focusNode, propertyTreeData} = this.state;
        let {settings, workspace, ontology, configurations, aliasesToIRIMap, aliasesMap} = this.props;
        const focusNodeID = focusNode?.id;
        this.initGraphViewSettingsIfRequired(focusNodeID);
        const settingsContainerObject = getInitialisedContainer(settings, focusNodeID);
        return <FieldContainer style={{marginBottom: '8px', padding: '16px'}}>
            <ClassImagePrecedence
                settings={settings}
                classIRI={focusNodeID}
                onChange={() => {
                    this.setState({});
                    this.setupChanged();
                }}
            />
            <GraphViewStyleSettings
                workspace={workspace}
                graphStyle={settingsContainerObject}
                ontology={ontology}
                configurations={configurations}
                aliasesToIRIMap={this.props.aliasesToIRIMap}
                aliasesMap={aliasesMap}
                settings={settings}
                browseLanguage={settings?.browseLanguages?.find(bl => bl.isDefault === true)}
                onClose={undefined}
                openStyleFor={{elementId: focusNodeID}}
                onStyleSettingsChange={() => {
                }}
                styleContainerWidth={{}}
                containerStyle={{
                    backgroundColor: fieldContainerDefaultBackgroundColor
                }}
                hideProperty={['background-image']}
                elementObject={{
                    embeddedForType: true,
                    data: function (e) {
                        return {
                            typeIRIs: [focusNodeID]
                        }
                    },
                    isNode: function (e) {
                        return true;
                    }
                }}
            />
        </FieldContainer>;

    }

    initGraphViewSettingsIfRequired = (resourceIRI) => {
        let {settings} = this.props;
        this.initLabelPropertiesIfRequired(resourceIRI);
        let valueObject = settings.labelProperties?.[resourceIRI][GRAPH_VIEW_SETTINGS];
        if(!valueObject) {
            settings.labelProperties[resourceIRI][GRAPH_VIEW_SETTINGS] = {};
        }
        let graphStyle = settings.labelProperties?.[resourceIRI][GRAPH_VIEW_SETTINGS];
    }

    renderPropertyViewSetup = () => {
        let {configurations, ontology, settings, shapes, workspace, aliasesMap, classes, onChange, theme} = this.props;
        let {focusNode, propertyTreeData} = this.state;
        return <Grid item xs={12}>
            <H2Title style={{margin: '16px 0px'}}>Property Order</H2Title>
            <FieldContainer style={{marginBottom: '8px', padding: '16px'}}>
                <PropertyOrderSettings
                    settings={settings}
                    classIRI={getResourceId(focusNode)}
                    theme={theme}
                    propertyTreeData={propertyTreeData}
                    classes={classes}
                    onChange={() => this.setState({})}
                    onSwitchChange={this.setupChanged}
                />
            </FieldContainer>
        </Grid>;
    }

    render() {
        const {stepName} = this.props;

        return <AllPartsLayout
            header={undefined}
            leftComponentScroll={{y: 'hidden', x: 'hidden'}}
            leftComponentStyle={{paddingTop: '0px'}}
            leftComponentContainerStyle={{paddingRight: '0px'}}
            leftMainHeader={<MainHeaderBarLeft title={stepName}/>}
            leftComponent={this.getStepLeftContent()}
            middleActions={undefined}
            middleComponent={this.middleContent()}
        />;
    }

}

DataViewSetup.propTypes = {
    workspace: PropTypes.object,
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    stepName: PropTypes.string
};

export default withStyles(styles, {withTheme: true})(DataViewSetup);
