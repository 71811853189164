import React, { useEffect, useRef } from "react";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';

const DOMContent = ({elements}) => {
    const ref = useRef(null);
    useEffect(() => {
    }, [elements]);

    return <div ref={ref}>{parse(DOMPurify.sanitize(elements))}</div>;
};

export default DOMContent;
